// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-child-js": () => import("./../../../src/pages/about-child.js" /* webpackChunkName: "component---src-pages-about-child-js" */),
  "component---src-pages-about-page-js": () => import("./../../../src/pages/about-page.js" /* webpackChunkName: "component---src-pages-about-page-js" */),
  "component---src-pages-all-magazines-js": () => import("./../../../src/pages/all-magazines.js" /* webpackChunkName: "component---src-pages-all-magazines-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-award-gallery-js": () => import("./../../../src/pages/award-gallery.js" /* webpackChunkName: "component---src-pages-award-gallery-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-project-type-js": () => import("./../../../src/pages/project-type.js" /* webpackChunkName: "component---src-pages-project-type-js" */),
  "component---src-pages-project-types-js": () => import("./../../../src/pages/project-types.js" /* webpackChunkName: "component---src-pages-project-types-js" */),
  "component---src-pages-sheyi-bankale-js": () => import("./../../../src/pages/sheyi-bankale.js" /* webpackChunkName: "component---src-pages-sheyi-bankale-js" */),
  "component---src-pages-sheyi-project-type-js": () => import("./../../../src/pages/sheyi-project-type.js" /* webpackChunkName: "component---src-pages-sheyi-project-type-js" */),
  "component---src-pages-single-sheyi-js": () => import("./../../../src/pages/single-sheyi.js" /* webpackChunkName: "component---src-pages-single-sheyi-js" */)
}

